import { lazy } from 'solid-js'

export default {
    children: [
        { path: '/general' },
        { path: '/bookings' },
        { path: '/pricing' },
        { path: '/hosting' },
    ],
    component: lazy(() => import('../../pages/faq-page/faq-page.jsx')),
    path: '/faq',
}
