// -- External libraries
import { splitProps } from 'solid-js'

import { useImageAtomComponentController } from './image-atom-component-controller.js'
import './image-atom-component.scss'

export default function ImageAtomComponent (props) {
    const { mergedProps } = useImageAtomComponentController(props)
    const requiredAttributes = ['src', 'alt', 'placeholder']
    const [reqAttrs, styles, attrs] = splitProps(mergedProps, requiredAttributes, ['class'])

    const rootClasses = ['image-atom-component']
    if (styles.class) rootClasses.push(styles.class)

    return (
        <img
            class={rootClasses.join(' ')}
            src={reqAttrs.src}
            alt={reqAttrs.alt}
            {...attrs}
            onError={(e) => {
                e.target.src = reqAttrs.placeholder
            }}
        />
    )
}
