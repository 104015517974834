import BaseAPI from './base-api-client.js'
import { fetchResponseHandler } from './error-utils.js';

export default class PropertyAPI extends BaseAPI {
    static async create () {
        try {
            const response = await this.fetchSecure('/user/property', {
                body: {},
                method: 'POST'
            })

            return await fetchResponseHandler(response)
        } catch (error) {
            throw error
        }
    }

    static async show (id) {
        try {
            const response = await this.fetchSecure(`/user/property/${id}`, { method: 'GET' })

            return await fetchResponseHandler(response)
        } catch (error) {
            throw error
        }
    }

    static async update (id, body) {
        try {
            const response = await this.fetchSecure(`/user/property/${id}`, {
                body,
                method: 'PATCH'
            })

            return await fetchResponseHandler(response)
        } catch (error) {
            throw error
        }
    }

    static async connect (id) {
        try {
            const response = await this.fetchSecure(`/user/property/${id}/connect`, { method: 'GET' })

            return await fetchResponseHandler(response)
        } catch (error) {
            throw error
        }
    }

    static async disconnect (id) {
        try {
            await this.fetchSecure(`/user/property/${id}/disconnect`, { method: 'POST' })
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    static async list () {
        try {
            const response = await this.fetchSecure('/user/property', { method: 'GET' })

            return await fetchResponseHandler(response)
        } catch (error) {
            throw error
        }
    }

    static async search (body) {
        try {
            const response = await this.fetchSecure('/user/property/search', {
                body,
                method: 'POST'
            })

            return await fetchResponseHandler(response)
        } catch (error) {
            throw error
        }
    }

    static async delete (id) {
        try {
            await this.fetchSecure(`/user/property/${id}`, { method: 'DELETE' })
        } catch (error) {
            throw error
        }
    }
}