export const fetchResponseHandler = async (response) => {
    console.log(response)
    const data = await response.json()

    if (!response.ok) {
        let error = new Error('Unexpected Error')

        if (data) {
            error = new Error(data.message)
            error.statusCode = data.statusCode
        }

        throw error
    }

    return data
}
