import BaseAPI from './base-api-client.js'
import { fetchResponseHandler } from './error-utils.js'

export default class SessionAPI extends BaseAPI {
    static async store (request) {
        try {
            const response = await this.fetch('/session', {
                body: request,
                method: 'POST'
            })

            return fetchResponseHandler(response)
        } catch (error) {
            console.error('SessionAPI.store - Error:', error)
            throw error
        }
    }

    static async refresh (request) {}

    static async delete () {
        try {
            await this.fetchSecure('/session', { method: 'DELETE' })
        } catch (error) {
            console.error('SessionAPI.delete - Error:', error)
            throw error
        }
    }
}