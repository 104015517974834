// -- External libraries
import {
    createSignal,
    mergeProps,
    onCleanup,
    onMount
} from 'solid-js'

export function useModalOrganismComponentController (props) {
    console.log(props)
    const defaultProps = {}
    const mergedProps = mergeProps(defaultProps, props)

    const [
        modalReference,
        setModalReference,
    ] = createSignal(HTMLDivElement | undefined)

    const handleEscKey = (event) => {
        if (event.key === 'Escape') {
            mergedProps.onClose()
        }
    }

    onMount(() => {
        // Disable scrolling when the modal opens
        document.body.style.overflow = 'hidden'

        modalReference().focus()
        document.addEventListener('keyup', handleEscKey)
    })

    onCleanup(() => {
        document.body.style.overflow = '' // Restore scrolling
        document.removeEventListener('keyup', handleEscKey)
    })

    return {
        mergedProps, setModalReference
    }
}
