import {
    createContext,
    useContext,
} from 'solid-js'

export const PublicTemplateContext = createContext({ isContextReady: () => false })

export function usePublicTemplate () {
    return useContext(PublicTemplateContext)
}
