import BaseAPI from './base-api-client.js';
import { fetchResponseHandler } from './error-utils.js';

export default class BookingAPI extends BaseAPI {
    static async create (bookingData) {
        try {
            const response = await this.fetchSecure('/booking', {
                body: bookingData,
                method: 'POST'
            });

            return await fetchResponseHandler(response);
        } catch (error) {
            throw error;
        }
    }

    static async listByPropertiesProfileId () {
        try {
            const response = await this.fetchSecure('/booking/properties', { method: 'GET' });

            return await fetchResponseHandler(response);
        } catch (error) {
            throw error;
        }
    }

    static async listByProfileId () {
        try {
            const response = await this.fetchSecure('/booking', { method: 'GET' });

            return await fetchResponseHandler(response);
        } catch (error) {
            throw error;
        }
    }

    static async show (bookingId) {
        try {
            const response = await this.fetchSecure(`/booking/${bookingId}`, { method: 'GET' });

            return await fetchResponseHandler(response);
        } catch (error) {
            throw error;
        }
    }

    static async update (bookingId, bookingData) {
        try {
            const response = await this.fetchSecure(`/booking/${bookingId}`, {
                body: bookingData,
                method: 'PATCH'
            });

            return await fetchResponseHandler(response);
        } catch (error) {
            throw error;
        }
    }

    static async cancel (bookingId, reasonString) {
        try {
            const response = await this.fetchSecure(`/booking/${bookingId}/cancel`, {
                body: { cancel_reason: reasonString },
                method: 'PATCH'
            });

            return await fetchResponseHandler(response);
        } catch (error) {
            throw error;
        }
    }

    static async accept (bookingId) {
        try {
            const response = await this.fetchSecure(`/booking/${bookingId}/accept`, { method: 'PATCH' });

            return await fetchResponseHandler(response);
        } catch (error) {
            throw error;
        }
    }

    static async pay (bookingId) {
        try {
            const response = await this.fetchSecure(`/booking/${bookingId}/pay`, { method: 'PATCH' });

            return await fetchResponseHandler(response);
        } catch (error) {
            throw error;
        }
    }

    static async reject (bookingId, reasonString) {
        try {
            const response = await this.fetchSecure(`/booking/${bookingId}/reject`, {
                body: { reject_reason: reasonString },
                method: 'PATCH'
            });

            return await fetchResponseHandler(response);
        } catch (error) {
            throw error;
        }
    }

    static async updatePrice (bookingId, bookingUrl) {
        try {
            const response = await this.fetchSecure(`/booking/${bookingId}/price`, {
                body: { bookingUrl: bookingUrl },
                method: 'POST'
            });

            return await fetchResponseHandler(response);
        } catch (error) {
            throw error;
        }
    }

    static async pay (bookingId, confirmationId) {
        try {
            const response = await this.fetchSecure(`/booking/${bookingId}/pay`, {
                body: { confirmation_id : confirmationId },
                method: 'PATCH'
            });

            return await fetchResponseHandler(response);
        } catch (error) {
            throw error;
        }
    }

    static async search (body) {
        try {
            const response = await this.fetchSecure('/booking/search', {
                body,
                method: 'POST'
            })

            return await fetchResponseHandler(response)
        } catch (error) {
            throw error
        }
    }

    static async report (bookingId, body) {
        try {
            const response = await this.fetchSecure(`/booking/${bookingId}/report`, {
                body: { message: body },
                method: 'POST'
            })

            return await fetchResponseHandler(response)
        } catch (error) {
            throw error
        }
    }

    static async checkDuplicate (body) {
        try {
            const response = await this.fetchSecure('/booking/check-duplicate', {
                body,
                method: 'POST'
            })

            return await fetchResponseHandler(response)
        } catch (error) {
            throw error
        }
    }

    static async contact (message, bookingId) {
        try {
            const response = await this.fetchSecure(`/booking/${bookingId}/contact`, {
                body:  { message : message },
                method: 'POST'
            })
            console.log('Message Sent')
            return await fetchResponseHandler(response)
        } catch (error) {
            console.error(`Error sending message to recipient of booking with ID: ${bookingId} -`, error);
            throw error;
        }
    }
}
