// -- External libraries
import { splitProps } from 'solid-js'

import { useBarAtomComponentController } from './bar-atom-component-controller.js'
import './bar-atom-component.scss'

export default function BarAtomComponent (props) {
    const { mergedProps } = useBarAtomComponentController(props)
    const requiredAttributes = ['direction']
    const [reqAttrs, styles, attrs] = splitProps(mergedProps, requiredAttributes, ['class'])

    const rootClasses = ['bar-atom-component']
    if (styles.class) rootClasses.push(styles.class)

    if (reqAttrs.direction === 'vertical') rootClasses.push('bar-atom-component--vertical')
    else rootClasses.push('bar-atom-component--horizontal')

    return (
        <div class={rootClasses.join(' ')} {...attrs}>
            {attrs.children}
        </div>
    )
}
