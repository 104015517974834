import {
    useLocation,
    useNavigate,
} from '@solidjs/router'
import {
    createContext,
    createEffect,
    createSignal,
    mergeProps,
    onMount,
    splitProps,
    useContext,
} from 'solid-js'
import { createStore } from 'solid-js/store'
import countStore from '../../../../stores/count-store.js'
import errorStore from '../../../../stores/error-store.js'
import sessionStore from '../../../../stores/session-store.js'
import userStore from '../../../../stores/user-store.js'
import BookingService from '../../../services/booking-service'
import PropertyService from '../../../services/property-service'

export const PublicTemplateContext = createContext({ isContextReady: () => false })

export const usePublicTemplateComponentController = (props) => {
    const [
        requestAttributes,
        styles,
        attributes,
    ] = splitProps(
        mergeProps({
            overflow: false,
            showFooter: false,
            showHeader: false,
            showHeaderMenu: false,
            showSidebar: false,
        }, props),
        ['logo'],
        ['class'],
    )

    const navigate = useNavigate()

    const location = useLocation()

    const [
        showHumburgetMenu,
        setshowHumburgetMenu,
    ] = createSignal()
    const [
        showAuthModal,
        setShowAuthModal,
    ] = createSignal(false)
    const [
        accessToken,
        setAccessToken,
    ] = createSignal()
    const [
        isAuthenticated,
        setIsAuthenticated,
    ] = createSignal()

    const toggleHumburgerMenu = () => {
        setshowHumburgetMenu(previous => !previous)
    }

    console.log('usePublicTemplateComponentController')

    const [
        state,
        setState,
    ] = createStore({
        isContextReady: false,
        showFooter: false,
        showHeader: false,
        showHeaderMenu: false,
        showSidebar: false,
    })

    console.log('usePublicTemplateComponentController - state', state)

    const value = {
        isContextReady: () => true,
        setShowFooter: (value) => setState('showFooter', value),
        setShowHeader: (value) => setState('showHeader', value),
        setShowHeaderMenu: (value) => setState('showHeaderMenu', value),
        setShowSidebar: (value) => setState('showSidebar', value),
        state,
        updateAuthenticatedData: () => updateAuthenticatedData(),
    }

    const logout = async () => {
        try {
            setAccessToken(null)
            await sessionStore.clear()
            userStore.clear()
        } catch (error) {
            console.error(error)
            errorStore.addError(error, 'Logout in sidebarNav')
        }
    }

    const properties = []
    const stays = []
    const bookings = []

    onMount(async () => {
        setAccessToken(await sessionStore.getAccessToken())
        setIsAuthenticated(await sessionStore.getAccessToken())
        countStore.set({ isLoaded: true })
    })

    createEffect(async () => {
        const token = await accessToken()
        await setIsAuthenticated(token ? true : false)

        if (token) {
            await updateAuthenticatedData() // Call the method on mount if authenticated
        }
    })

    const updateAuthenticatedData = async () => {
        try {
            const properties = await PropertyService.list()
            if (properties) {
                countStore.set({ numOfProperties: properties.filter(p => p.connection_status).length })
            }

            const stays = await BookingService.listByProfileId()
            if (stays.bookingList) {
                countStore.set({ numOfStays: stays.bookingList.length })
            }

            const bookings = await BookingService.listByPropertiesProfileId()
            if (bookings.bookingList) {
                countStore.set({ numOfBookings: bookings.bookingList.length })
            }

            countStore.set({ isLoaded: true })
        } catch (error) {
            console.error('Error fetching authenticated data:', error)
            errorStore.addError(error, 'updateAuthenticatedData')
        }
    }

    const getMenuItemProps = (path) => {
        return {
            class: 'public-template-component__main__sidebar__menu__item',
            classList: { 'public-template-component__main__sidebar__menu__item--selected': location.pathname === path },
            href: path,
        }
    }

    const getRelevantDashboardPath = () => {
        if (countStore.get('numOfProperties')) {
            return '/properties'
        }
        if (countStore.get('numOfStays')) {
            return '/stays'
        }
        return '/welcome'
    }

    const openAuthModal = () => {
        setShowAuthModal(true)
    }

    const closeAuthModal = () => {
        setShowAuthModal(false)
    }

    console.log('attrs', attributes)
    return {
        attrs: attributes,
        closeAuthModal,
        countStore,
        getMenuItemProps,
        getRelevantDashboardPath,
        isAuthenticated,
        logout,
        openAuthModal,
        properties,
        reqAttrs: requestAttributes,
        sessionStore,
        showAuthModal,
        showHumburgetMenu,
        state,
        stays,
        styles,
        toggleHumburgerMenu,
        value,
    }
}
