import userStore from '../../stores/user-store.js'
import UserAPI from '../data/user-api-client.js'

export default class UserService {
    static async store (email, password) {
        try {
            const user = await UserAPI.store({
                email,
                password,
            })
            await userStore.setEmail(email)
            return user
        } catch (error) {
            console.error('UserService.store - Error:', error)
            throw error
        }
    }

    static async show () {
        try {
            return await UserAPI.show()
        } catch (error) {
            console.error('UserService.show - Error:', error)
            throw error
        }
    }

    static async updateCredentials (currentPassword, newPassword) {
        try {
            return await UserAPI.updateCredentials({
                current_password: currentPassword,
                new_password: newPassword,
            })
        } catch (error) {
            console.error('UserService.updateCredentials - Error:', error)
            throw error
        }
    }

    static async requestPasswordReset (email, reset_link) {
        try {
            return await UserAPI.requestPasswordReset({
                email,
                reset_link,
            })
        } catch (error) {
            console.error('UserService.requestPasswordReset - Error:', error)
            throw error
        }
    }

    static async passwordReset (token, new_password) {
        try {
            return await UserAPI.passwordReset(token, { new_password })
        } catch (error) {
            console.error('UserService.passwordReset - Error:', error)
            throw error
        }
    }

    static async requestEmailVerification (redirect_url) {
        try {
            return await UserAPI.requestEmailVerification({ redirect_url })
        } catch (error) {
            console.error('UserService.requestEmailVerification - Error:', error)
            throw error
        }
    }

    static async verifyEmail (token) {
        try {
            const {
                data,
            } = await UserAPI.verifyEmail(token)
            console.log('UserService.verifyEmail - data:', data)
            return data
        } catch (error) {
            console.error('UserService.verifyEmail - Error:', error)
            throw error
        }
    }

    static async delete (password) {
        try {
            return await UserAPI.delete({ password })
        } catch (error) {
            console.error('UserService.delete - Error:', error)
            throw error
        }
    }
}
