// src/app/routes/router.jsx
import { useLocation, useRoutes } from '@solidjs/router'
import { createEffect } from 'solid-js'
import { usePublicTemplate } from '../components/templates/public-template/public-template-component-context.js'
import * as routeFiles from './web/web-routing.js'

const routes = Object.values(routeFiles)

const wrappedRoutes = routes.map((route) => {
    const { showHeader = true, showHeaderMenu = true, showFooter = true, showSidebar = false, ...rest } = route

    // Create a wrapper component that applies the template settings
    const TemplateControlledComponent = (props) => {
        const templateContext = usePublicTemplate()
        const location = useLocation()

        createEffect(() => {
            console.log('Route changed to:', location.pathname)
            if (templateContext.isContextReady && templateContext.isContextReady()) {
                templateContext.setShowHeader(showHeader)
                templateContext.setShowHeaderMenu(showHeaderMenu)
                templateContext.setShowFooter(showFooter)
                templateContext.setShowSidebar(showSidebar)
                templateContext.setShowSidebar(showSidebar)
            }
        })

        const Component = route.component
        return <Component {...props} />
    }

    return {
        ...rest,
        component: TemplateControlledComponent, // Use the wrapped component
    }
})

const Routes = useRoutes(wrappedRoutes)
export default Routes
